<template>
    <b-card>
        <b-form class="mt-2">
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        label="Name"
                        label-for="account-name"
                    >
                        <b-form-input
                        name="name"
                        v-model="detail.name"
                        placeholder="Name"
                        :disabled="!$can('update', 'Profile')"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="E-mail"
                        label-for="account-e-mail"
                    >
                        <b-form-input
                        v-model="detail.email"
                        name="email"
                        placeholder="Email"
                        :disabled="!$can('update', 'Profile')"
                        />

                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mr-1"
                        @click="handleUpdate()"
                        :disabled="!$can('update', 'Profile')"
                    >
                        Save changes
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2"
                        @click="handleReset()"
                        :disabled="!$can('update', 'Profile')"
                    >
                        Reset
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BLink, BOverlay,BForm,BFormGroup
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BLink,
        BOverlay,
        BForm,
        BFormGroup
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            detail: {},
        }
    },
    mounted() {
        this.getProfile()
    },
    methods: {
        getProfile() {
        this.$http.get('/v1/profile')
            .then(response => {
            this.detail = response.data.data
            })
            .catch(error => {
            this.errorToast(error.response.data.messages)
            })
        },
        handleUpdate() {
        this.$http.post('/v1/profile/update', this.detail)
            .then(() => {
            this.successToast()
            })
            .catch(error => {
            this.errors = error.response.data.errors
            this.errorToast(error.response.data.messages)
            })
        },
        handleReset() {
            this.detail = {}
            this.getProfile()
        },
        successToast() {
        this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Berhasil',
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
        },
        errorToast(message) {
        this.$toast({
            component: ToastificationContent,
            props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            },
        })
        },
    },
}
</script>